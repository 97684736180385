"use client";
import { Get } from "@/services/fetchServices";
import { createContext, useContext, useState, useMemo, useEffect } from "react";
import Loader from "@/components/shared/Loader"; // Global Loader əlavə edirik

// Create a context for the quiz data
export const QuizContext = createContext(null);

// Define a component to provide the quiz context to its children
export function QuizContextProvider({ children }) {
  // State variables for storing quiz data
  const [allCategories, setAllCategories] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDatas = async () => {
    setLoading(true);
    try {
      const [categories, courses, teamData] = await Promise.all([
        Get("quiz/v1/categories/all"), // Fetch categories
        Get("quiz/v1/courses/search?size=100"), // Fetch courses
        Get("quiz/v1/teams"), // Fetch teamData
      ]);

      // Update state with the fetched data
      setAllCategories(categories);
      setAllCourses(courses?.content);
      setTeam(teamData?.content);
    } catch (error) {
      console.error("Failed to fetch quiz data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDatas();
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => {
    return {
      allCategories,
      allCourses,
      team,
      loading,  
    };
  }, [allCategories, allCourses, team, loading]);

  return (
    <QuizContext.Provider value={contextValue}>{children}</QuizContext.Provider>
  );
}

// Custom hook to access the quiz context
export function useQuizContext() {
  return useContext(QuizContext);
}
