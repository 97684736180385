"use client";
import Image from "next/image";
import React, { useState } from "react";
import { IoIosArrowUp, IoMdClose } from "react-icons/io";
const ChatBox = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState("");
  const [userQuestions, setUserQuestions] = useState([
    {
      question: "Kursun qiyməti",
    },
    {
      question: "Kurs cədvəli",
    },
    {
      question: "Əlavə sual",
    },
  ]);
  return (
    <section className={`chat-box ${isActive ? "" : "passive"}`}>
      <div className="box-head">
        <button
          type="button"
          className="box-btn"
          onClick={() => setIsActive(!isActive)}
        >
          {isActive ? <IoMdClose /> : <IoIosArrowUp />}
        </button>
        <div className="box-name">
          <div className="box-logo">
            <Image
              src="/images/logo/ingress-logo-new.png"
              width={25}
              height={25}
              alt="logo"
            />
          </div>
          <div>Ingress Assistant</div>
        </div>
      </div>
      <div className="box-content">
        <div className="question">
          Sizə aşağıdakı mövzulardan hansı üçün dəstək olmağımı istəyərsiniz?
        </div>
        <div className="user-questions">
          {userQuestions.map((userQuestion, index) => (
            <div
              className="user-question"
              key={index}
              onClick={() => setActiveQuestion(userQuestion.question)}
            >
              {userQuestion.question}
              <div></div>
            </div>
          ))}
        </div>
      </div>
      {activeQuestion && (
        <div className="message-box">
          <div className="message">
            {activeQuestion}
            <div></div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ChatBox;
