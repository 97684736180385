"use client";
import {
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import React, { forwardRef } from "react";
import { IoChevronDown } from "react-icons/io5";
import Link from "next/link";

import { useQuizContext } from "@/context/context";
import { useI18n } from "@/locales/client";

const MobileNavbar = forwardRef(function MobileNavbar(
  { isNavOpen, setIsNavOpen },
  ref
) {
  const closeDrawer = () => setIsNavOpen(false);

  const [open, setOpen] = React.useState(0);
  const { allCourses, allCategories } = useQuizContext();
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const t = useI18n();

  return (
    <>
      <div
        ref={ref}
        open={isNavOpen}
        className={`${
          isNavOpen ? "showNav" : "closeNav"
        } fixed z-[100000] overflow-y-scroll pointer-events-auto bg-white box-border w-full shadow-2xl shadow-blue-gray-900/10 top-0 left-0 border-2`}
      >
        <div className="mb-2 flex items-center justify-between p-4 !bg-white z-[100000]">
          <Typography variant="h5" color="blue-gray">
            <svg
              width="200"
              height="40"
              viewBox="0 0 200 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="flex flex-col"
            >
              <text x="0" y="30" fontSize="18" fontWeight="600" fill="#233131">
                Ingress
              </text>
              <text x="80" y="30" fontSize="18" fontWeight="600" fill="#0554f2">
                Academy
              </text>
            </svg>
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            title="Close"
            aria-label="Close"
            onClick={closeDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <List className="!bg-white">
          <Accordion
            open={open === 1}
            icon={
              <IoChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === 1 ? "rotate-180" : ""
                }`}
              />
            }
          >
            <ListItem className="p-0" selected={open === 1}>
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="border-b-0 p-3"
              >
                <Typography color="blue-gray" className="mr-auto font-normal">
                  {t("courses")}
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                <p className="p-3 font-bold">{t("cc")}</p>

                {allCategories?.map(({ key,name }, index) => (
                  <Link
                    aria-label="Category"
                    rel="canonical"
                    key={index}
                    href={`/courses?category=${key}`}
                    onClick={() => closeDrawer()}
                    className=" text-[#10162f]"
                  >
                    <ListItem>{name}</ListItem>
                  </Link>
                ))}

                <p className="p-3 font-bold">{t("ac")}</p>
                {allCourses
                  ?.sort((a, b) => a.rating - b.rating)
                  ?.map(({ key,name, parentId }, index) => (
                    <Link
                      aria-label="Course"
                      rel="canonical"
                      key={index}
                      href={`/courses/${
                        allCategories.find((c) => c.id === parentId)?.key
                      }/${key}`}
                      onClick={() => closeDrawer()}
                      className=" text-[#10162f]"
                    >
                      <ListItem>{name}</ListItem>
                    </Link>
                  ))}
              </List>
            </AccordionBody>
          </Accordion>

          <Link
            aria-label="About"
            rel="canonical"
            href="/about"
            className="!text-[#263238]"
            onClick={() => closeDrawer()}
          >
            <ListItem>{t("about")}</ListItem>
          </Link>
          <Link
            aria-label="Contact"
            rel="canonical"
            href="/contact"
            className="!text-[#263238]"
            onClick={() => closeDrawer()}
          >
            <ListItem>{t("contact")}</ListItem>
          </Link>
          {/* <Link
            aria-label="Corporate"
            rel="canonical"
            href="/corporate"
            className="!text-[#263238]"
            onClick={() => closeDrawer()}
          >
            <ListItem>{t("corporate")}</ListItem>
          </Link> */}
        </List>
      </div>
    </>
  );
});
export default MobileNavbar;
