"use client";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";

import { Post } from "@/services/fetchServices";
import { useI18n } from "@/locales/client";

const UserProfile = ({ setUser }) => {
  const menuProfile = useRef(null);
  const listRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState(false);
  const [userData, setUserData] = useState({});

  const router = useRouter();

  const t = useI18n();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
  }, [localStorage]);

  useEffect(() => {
    document.body.addEventListener("click", onClickOutside);

    return () => document.removeEventListener("click", onClickOutside);
  }, []);

  const onClickOutside = (e) => {
    const targetElement = e.target;
    if (
      !listRef?.current?.contains(targetElement) &&
      !menuProfile?.current?.contains(targetElement)
    ) {
      removeProfileMenu();
    }
  };

  const removeProfileMenu = () => {
    document.querySelector("#userProfileMenu")?.classList?.remove("active");
    setActiveMenu(false);
  };

  const toggleProfileMenu = () => {
    if (!activeMenu) {
      menuProfile?.current?.classList?.add("active");
      setActiveMenu(true);
      return;
    }
    menuProfile?.current?.classList?.remove("active");
    setActiveMenu(false);
  };

  const logOut = async () => {
    await Post("users/auth/sign-out")
      .then(() => {
        localStorage.removeItem("userData");
        setUser(null);
        router.push("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <li className="relative flex justify-center items-center flex-col">
      <button type="button" onClick={toggleProfileMenu} ref={listRef}>
        <div className="table">
          <img
            src={userData.avatarUrl}
            alt="User"
            className="ml-2 w-12 h-12 rounded-[50%]"
          />
        </div>
      </button>

      <div id="userProfileMenu" className="profileMenu mt-1" ref={menuProfile}>
        <ul className="py-3">
          <li className="w-full pb-[1px] px-6 my-4">
            <Link
            aria-label="Profile"
            rel='canonical'
              href="/profile"
              className="w-full flex items-center group"
              onClick={() => {
                removeProfileMenu();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="black"
                aria-hidden="true"
                className="h-7 w-7 mr-4 group-hover:stroke-blue-900 transition-colors"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
              </svg>
              <p className="text-base text-black group-hover:text-blue-900 transition-colors">
                {t("mp")}
              </p>
            </Link>
          </li>
          <li className="w-full pb-[1px] px-6 my-4">
            <Link
            aria-label="Edit"
            rel='canonical'
              href="/edit"
              className="w-full flex items-center group"
              onClick={() => {
                removeProfileMenu();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="black"
                aria-hidden="true"
                className="h-7 w-7 mr-4 group-hover:stroke-blue-900 transition-colors"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                ></path>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
              </svg>

              <p className="text-base text-black group-hover:text-blue-900 transition-colors">
                {t("ep")}
              </p>
            </Link>
          </li>

          <div className="px-6">
            <hr className="mt-8 mb-2 border-gray-500" />
          </div>

          <li className="w-full pb-[1px] px-6 my-4">
            <button className="w-full flex items-center group" onClick={logOut}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="black"
                aria-hidden="true"
                className="h-7 w-7 mr-4 group-hover:stroke-red-900 transition-colors"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                ></path>
              </svg>
              <p className="text-base group-hover:text-red-900 transition-colors">
                {t("logout")}
              </p>
            </button>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default UserProfile;
