import(/* webpackMode: "eager" */ "/app/components/ui/ChatBox.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ui/footer/NewFooter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ui/header/Header.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuizContextProvider"] */ "/app/context/context.js");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProviderClient"] */ "/app/locales/client.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo/ingress-logo-new.png");
;
import(/* webpackMode: "eager" */ "/app/styles/global.css");
;
import(/* webpackMode: "eager" */ "/app/styles/main.scss");
