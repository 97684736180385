"use client";
import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { CgMail } from "react-icons/cg";
import { IconButton, Typography } from "@material-tailwind/react";
import { IoIosClose, IoIosMenu } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { usePathname } from "next/navigation";

import MobileNavbar from "./MobileNavbar";
import CoursesDrop from "../dropdown/CoursesDrop";
import Dropdown1 from "../dropdown/Dropdown1";
import UserProfile from "../UserProfile";
import { useChangeLocale, useCurrentLocale, useI18n } from "@/locales/client";
import { GrLanguage } from "react-icons/gr";

const Header = () => {
  const headerRef = useRef();
  const menuRef = useRef();
  const mobRef = useRef();

  const pathName = usePathname();
  const changeLocale = useChangeLocale();
  const [user, setUser] = useState(null);
  const [isActiveSearch, setIsActiveSearch] = useState(false);

  const [isLanguageModalActive, setIsLanguageModalActive] = useState(false);

  const t = useI18n();
  const currentLocale = useCurrentLocale();
  const userData =
    typeof window !== "undefined" && localStorage.getItem("userData");

  useEffect(() => {
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, [pathName]);

  // const handleSearch = () => {
  //   searchRef?.current.classList.toggle("active");
  // };

  const [isNavOpen, setIsNavOpen] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        window?.innerWidth >= 960 && setIsNavOpen(false);
      });
    }
  }, []);

  if (typeof window !== "undefined") {
    window.addEventListener("click", (e) => {
      if (
        !e.composedPath().includes(menuRef.current) &&
        !e.composedPath().includes(mobRef.current)
      ) {
        setIsNavOpen(false);
      }
    });
    window.addEventListener("scroll", function () {
      var header = headerRef.current;
      var scrollHeight = window.scrollY;

      if (scrollHeight > 48) {
        header?.classList.add("scroll-header");
      } else {
        header?.classList.remove("scroll-header");
      }
    });
  }

  const handleChangeLanguage = (e) => {
    const selectedOption = e.target.value;
    changeLocale(selectedOption);
  };
  return (
    <>
      <header ref={headerRef} className="sticky top-0 z-[1000] ">
        <div className="lg:bg-[#0c5adb] bg-white text-white flex justify-center text-sm p-[0.6rem]">
          <div className="lg:flex lg:w-full items-center justify-between hidden px-0 xl:px-[16px] md:mx-[30px] ">
            <div className="flex items-center justify-center gap-6">
              <Link
                aria-label="Email"
                href="mailto:info@ingress.az"
                className="flex items-center justify-center gap-1 font-['Muli_sans-serif']"
              >
                <CgMail />
                <div>info@ingress.az</div>
              </Link>
              <Link
                aria-label="Location"
                target="_blank"
                href="https://www.google.com/maps?q=Ingress Academy"
                className="flex items-center justify-center gap-1 font-['Muli_sans-serif']"
              >
                <FaLocationDot />
                <div>{t("locationDetail")}</div>
              </Link>
              <Link
                aria-label="Phone"
                className="flex items-center justify-center gap-1 font-['Muli_sans-serif']"
                href="tel:+994 77 599 99 40"
              >
                <BsFillTelephoneFill />
                <div>+994 77 599 99 40</div>
              </Link>
            </div>
            <div className="flex items-center justify-center gap-3 text-base">
              <div className="">
                <Link
                  aria-label="Facebook"
                  target="_blank"
                  href="https://www.facebook.com/ingress.academy"
                >
                  <FaFacebook />
                </Link>
              </div>
              <div className="">
                <Link
                  aria-label="LinkedIn"
                  target="_blank"
                  href="https://www.linkedin.com/school/ingress-services"
                >
                  <FaLinkedin />
                </Link>
              </div>
              <div className="">
                <Link
                  aria-label="Youtube"
                  target="_blank"
                  href="https://www.youtube.com/channel/UC4AT3cEfmyV-RcwxlJVBZ7Q"
                >
                  <FaYoutube />
                </Link>
              </div>
              <div className="">
                <Link
                  aria-label="Whatsapp"
                  target="_blank"
                  href="https://wa.me/+994775999940"
                >
                  <FaWhatsapp />
                </Link>
              </div>
              <div>
                <Link
                  aria-label="Instagram"
                  target="_blank"
                  href="https://www.instagram.com/ingress_academy/"
                >
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:hidden p-2">
            <Link
              aria-label="Home"
              href="/"
              className="flex gap-2 items-center"
            >
              <img
                src="/images/logo/logo.webp"
                width={100}
                height={100}
                alt="Ingress Academy Logo"
                property="true"
                className="h-auto w-[50px] md:w-[48px]"
              />
              <div>
                <div className="uppercase text-[18px] font-semibold">
                  <svg
                    width="200"
                    height="40"
                    viewBox="0 0 200 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <text
                      x="0"
                      y="30"
                      fontSize="18"
                      fontWeight="600"
                      fill="#233131"
                    >
                      Ingress
                    </text>
                    <text
                      x="80"
                      y="30"
                      fontSize="18"
                      fontWeight="600"
                      fill="#0554f2"
                    >
                      Academy
                    </text>
                  </svg>
                </div>
                <div className="text-[#586268] tracking-[2px] text-[10px]">
                  empowered by innovation
                </div>
              </div>
            </Link>
          </div>
        </div>
        <nav className="relative xl:px-[16px] h-[80px] lg:bg-[#FFF] flex items-center bg-[#0554f2]">
          <div className="flex items-center !justify-between px-5 md:px-0 md:mx-[30px] w-full">
            <ul className="flex items-center h-full">
              <li className="lg:w-[250px] w-[150px]">
                <Link
                  aria-label="Home"
                  href="/"
                  className="flex gap-2 items-center"
                >
                  <img
                    src="/images/logo/logo.webp"
                    width={100}
                    height={100}
                    alt="Ingress Academy Logo"
                    property="true"
                    className="h-auto w-[50px] md:w-[48px lg:block hidden"
                  />
                  <div className=" flex-col justify-center md:flex">
                    <div className="uppercase lg:text-[#233131] text-white text-[16px] md:text-[18px] font-semibold">
                      Ingress <span className="lg:text-[#3f79ff]">Academy</span>
                    </div>
                    <div className="lg:block hidden text-[8px] md:text-[10px] tracking-[2.3px] md:tracking-[2px] text-[#586268]">
                      empowered by innovation
                    </div>
                  </div>
                </Link>
              </li>
            </ul>

            <ul className="flex items-center h-full mr-[-10px] justify-between">
              <div className="hidden lg:flex items-center h-full gap-1">
                <li>
                  <CoursesDrop />
                </li>
                <li>
                  {/* <Dropdown1 title="Community" list={communityDropdown} /> */}
                </li>
                <li>
                  <Dropdown1 title="About" footer={false} />
                </li>
                <li>
                  <Dropdown1
                    title="Contact"
                    // list={contactDropdown}
                    footer={false}
                  />
                </li>
                {/* <li>
                  <Dropdown1 title="Corporate" footer={false} />
                </li> */}
              </div>
              {/* <li className="relative hidden lg:block">
                <button
                  type="button"
                  className="h-[30px] w-[30px] text-xl border rounded-md -mr-5 text-white bg-[#2554f2] flex items-center justify-center"
                  onClick={() => setIsActiveSearch(!isActiveSearch)}
                >
                  {isActiveSearch ? <IoIosClose /> : <IoIosSearch />}
                </button>
                <div
                  className={`${
                    isActiveSearch ? "opacity-100" : "opacity-0"
                  } absolute top-[55px] bg-white duration-700 right-[5px] w-[250px] p-4 border border-r-0 border-l-0 border-b-0 border-t-[3px] border-[#0c5adb]`}
                >
                  <div className="relative flex items-center justify-between bg-[#f4f6f9] text-gray-500 border rounded-3xl">
                    <input
                      type="text"
                      placeholder="Type your keyword"
                      className="w-[80%] outline-none bg-inherit pl-4 py-2 placeholder:text-base text-base rounded-3xl"
                    />
                    <button className="bg-[#2554f2] text-white p-2 text-2xl rounded-r-3xl">
                      <IoIosSearch />
                    </button>
                    <div className="absolute -right-[18px] -top-[25px] border-b-[8px] border-b-[#0c5adb] border-r-transparent border-l-transparent border-r-[10px] border-l-[10px]"></div>
                  </div>
                </div>
              </li> */}
              {user ? (
                <div className="ml-4">
                  <UserProfile user={user} setUser={setUser} />
                </div>
              ) : (
                <li className="hidden lg:flex gap-2 ml-[15px]">
                  {/* <Link
                    href="/login"
                    className="text-sm font-[700] rounded-md text-[#2554f2] border border-[#2554f2] px-3 py-1 hover:shadow-md"
                  >
                    <Typography
                      variant="small"
                      className="font-bold !p-0 cursor-pointer"
                    >
                      {t("login")}
                    </Typography>
                  </Link> */}
                  {/* <Link
                    href="/register"
                    className="text-sm font-[700] px-3 py-1 text-white bg-[#2554f2] border rounded-md hover:shadow-md"
                  >
                    {t("register")}
                  </Link> */}
                </li>
              )}
              {/* <li
                className="lg:hidden cursor-pointer relative"
                onClick={() => {
                  setIsLanguageModalActive(!isLanguageModalActive);
                }}
              >
                <GrLanguage size={22} color="white" />
                {isLanguageModalActive && (
                  <div className="absolute top-[30px] bg-white flex flex-col items-start rounded-sm text-base">
                    <button
                      className="px-2 py-1"
                      onClick={() => changeLocale("en")}
                    >
                      English
                    </button>
                    <hr className="h-[1px] w-full" />
                    <button
                      className="px-2 py-1"
                      onClick={() => changeLocale("az")}
                    >
                      Azerbaijan
                    </button>
                  </div>
                )}
              </li> */}
              <li
                className="lg:hidden text-white cursor-pointer"
                ref={menuRef}
                onClick={() => setIsNavOpen(!isNavOpen)}
              >
                <IoIosMenu className="text-[30px]" />
              </li>
              {/* <li>
                <select
                  value={currentLocale}
                  onChange={handleChangeLanguage}
                  name="language"
                  id="language"
                  className="cursor-pointer h-8 px-3 border border-[#ddd] appearance-none shadow-sm rounded-md outline-none text-base hidden lg:block"
                >
                  <option value="en">En</option>
                  <option value="az">Az</option>
                </select>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
      <MobileNavbar
        ref={mobRef}
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
      />
      <div
        onClick={() => setIsNavOpen(false)}
        className={`fixed top-0 bottom-0 inset-0 w-full h-full pointer-events-auto z-[9995] bg-black bg-opacity-60 backdrop-blur-sm ${
          isNavOpen ? "opacity-100 flex" : "opacity-0 hidden"
        }`}
      ></div>
    </>
  );
};

export default Header;
