"use client";
import Link from "next/link";
import { Button } from "@material-tailwind/react";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";

import useDrop from "../useDrop";
import { useQuizContext } from "@/context/context";
import { useI18n } from "@/locales/client";

const CoursesDrop = () => {
  const { allCategories, allCourses } = useQuizContext();
  const { show, toggleDropdown, dropRef, dropBtn } = useDrop();
  const t = useI18n();
  return (
    <>
      <div className="">
        <button
          ref={dropBtn}
          type="button"
          className=" flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
          aria-expanded="false"
          onClick={toggleDropdown}
        >
          <span className="font-['Muli_sans-serif'] font-normal text-[19px]">
            {t("courses")}
          </span>
          {show === "hide-drop" ? (
            <MdKeyboardArrowDown />
          ) : (
            <MdKeyboardArrowUp />
          )}
        </button>

        <div
          ref={dropRef}
          className={`mt-[45px] h-screen absolute left-0 z-10 flex w-screen ${show} dropdown`}
        >
          <div className="flex-auto h-screen border overflow-hidden rounded-sm bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="bg-[#2554f2] text-[#fff] p-[16px]">
              <h3>{t("cc")}</h3>
            </div>
            <div className="grid grid-cols-3 gap-2 p-4 overflow-auto">
              {allCategories?.map(({ name,key }, index) => (
                <Link
                aria-label="Category"
                rel='canonical'
                  onClick={toggleDropdown}
                  href={`/courses?category=${key}`}
                  key={index}
                  className="group relative flex gap-x-6 rounded-lg p-2 hover:bg-[#e9ebf1] w-full"
                >
                  <div>
                    <div className="font-semibold text-gray-900">{name}</div>
                  </div>
                </Link>
              ))}
            </div>

            <div className="bg-[#2554f2] text-[#fff] p-[16px] flex justify-between items-center">
              <Link aria-label="See all courses" rel='canonical' onClick={toggleDropdown} href="/courses">
                <Button size="sm" color="white" className="btn-1 mr-4">
                  {t("sac")}
                </Button>
              </Link>
            </div>
            <div className="grid grid-cols-3 gap-2 p-4 max-h-[300px] overflow-auto">
              {allCourses
                ?.sort((a, b) => a.rating - b.rating)
                ?.map(({ key,name, parentId }, index) => (
                  <Link
                  aria-label="Category"
                  rel='canonical'
                    onClick={toggleDropdown}
                    href={`/courses/${
                      allCategories.find((c) => c.id === parentId)?.key
                    }/${key}`}
                    key={index}
                    className="group relative flex gap-x-6 rounded-lg p-2 hover:bg-[#e9ebf1] w-full"
                  >
                    <div>
                      <div className="font-semibold text-gray-900">{name}</div>
                    </div>
                  </Link>
                ))}
            </div>

            {/* <div className="divide-y divide-slate-200">
              <div className="additions">
                <span>Explore</span>
                <Button size="sm" color="blue">
                  Upcoming Courses
                </Button>
              </div>
              <div className="additions">
                <span>Don't know where to begin?</span>
                <Button size="sm" color="blue">
                  Learning Path
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesDrop;
