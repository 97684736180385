import axios from "axios";
import { getCookie } from "cookies-next";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL_LOCAL
    : process.env.REACT_APP_API_BASE_URL_LOCAL;

axios.defaults.baseURL = baseUrl;

const Get = async (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/${url}`, {
        headers: {
          "Accept-Language": getCookie("Next-Locale"),
        },
      })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
        console.log(err?.response?.data);
      });
  });
};

const Post = async (url, data, header) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, header)
      .then((res) => {
        console.log(res);
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
        console.log(err?.response?.data);
      });
  });
};

const Delete = async (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then((res) => {
        resolve(res.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
        console.log(err?.response?.data);
      });
  });
};

const Put = async (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
        console.log(err?.response?.data);
      });
  });
};

const getWithFetch = async (url, locale) => {
  const domain =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_BASE_URL_LOCAL
      : process.env.NEXT_PUBLIC_DOMAIN;
  try {
    const res = await fetch(`${domain}/${url}`, {
      headers: {
        "Accept-Language": locale,
      },
    });

    return await res.json();
  } catch (err) {
    return [];
  }
};

export { baseUrl, Get, Post, Delete, Put, getWithFetch };
