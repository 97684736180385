import React from "react";

const Loader = ({ height = "100vh" }) => {
  return (
    <div className="w-full flex items-center justify-center" style={{ height }}>
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
    </div>
  );
};

export default Loader;
