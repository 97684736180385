"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import { FaLocationDot } from "react-icons/fa6";
import {
  FaLinkedin,
  FaFacebook,
  FaYoutube,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import { BsFillTelephoneFill } from "react-icons/bs";

import { Get } from "@/services/fetchServices";
import { useI18n } from "@/locales/client";

const NewFooter = () => {
  const currentYear = new Date().getFullYear();
  const links = [
    // { name: "Corporate", url: "/corporate" },
    { name: "About", url: "/about" },
    { name: "Contact", url: "/contact" },
  ];
  const [blogs, setBlogs] = useState([]);

  const t = useI18n();

  // const chooseRandomBlogs = (blogs) => {
  //   const index1 = Math.floor(Math.random() * blogs.length);
  //   let index2 = Math.floor(Math.random() * blogs.length);

  //   while (index2 === index1) {
  //     index2 = Math.floor(Math.random() * blogs.length);
  //   }

  //   setBlogs([blogs[index1], blogs[index2]]);
  // };
  // const getBlogs = async () => {
  //   const res = await Get("blog/v1/blogs");
  //   chooseRandomBlogs(res?.content);
  // };
  // useEffect(() => {
  //   getBlogs();
  // }, []);

  return (
    <footer>
      <div className="footer-container">
        <ul className="footer-elements">
          <li>
            <Link aria-label="Home" href="/">
              <img
                src="/images/new-logo.png"
                width={150}
                height={150}
                alt="Ingress Academy Logo"
                property="true"
              />
            </Link>
          </li>
          <li>
            INGRESS ACADEMY <div>Empowered by Innovation</div>
          </li>
          <li className="footer-links">
            <Link
            aria-label="Facebook"
              target="_blank"
              href="https://www.facebook.com/ingress.academy"
            >
              <FaFacebook color="white" />
            </Link>
            <Link
            aria-label="LinkedIn"
              target="_blank"
              href="https://www.linkedin.com/school/ingress-services"
            >
              <FaLinkedin color="white" />
            </Link>
            <Link
            aria-label="YouTube"
              target="_blank"
              href="https://www.youtube.com/channel/UC4AT3cEfmyV-RcwxlJVBZ7Q"
            >
              <FaYoutube color="white" />
            </Link>
            <Link aria-label="Whatsapp" target="_blank" href="https://wa.me/+994775999940">
              <FaWhatsapp color="white" />
            </Link>
            <Link
            aria-label="Instagram"
              target="_blank"
              href="https://www.instagram.com/ingress_academy/"
            >
              <FaInstagram color="#E1306C" />
            </Link>
          </li>
        </ul>
        <ul className="footer-elements">
          <li className="footer-element-line">
            <p></p>
            <div>{t("ql")}</div>
          </li>
          {links.map((subLink, index) => (
            <li key={index}>
              <Link aria-label="Pages"  href={subLink.url}>{t(subLink.name.toLowerCase())}</Link>
            </li>
          ))}
        </ul>
        <ul className="footer-elements">
          <li className="footer-element-line">
            <p></p>
            <div>{t("pp")}</div>
          </li>
          {blogs?.map((blog) => (
            <li key={blog?.id} className="footer-blog">
              <Link aria-label="Blog" href={`/blog/${blog?.id}`}>
                <div className="blog-image">
                  <img src={blog?.imageUrl} alt="blog" />
                </div>
                <div>{blog?.title}</div>
              </Link>
            </li>
          ))}
        </ul>
        <ul className="footer-elements contact-menu">
          <li className="footer-element-line">
            <p></p>
            <div>{t("ci")}</div>
          </li>
          <li>
            <div className="contact-logo">
              <FaLocationDot />
            </div>
            <div className="contact-text">
              <div>{t("location")}</div>
              <Link aria-label="Location" className="footer-detail" target="_blank" href="https://www.google.com/maps?q=Ingress Academy">{t("locationDetail")}</Link>
            </div>
          </li>
          <li>
            <div className="contact-logo">
              <CgMail />
            </div>
            <div className="contact-text">
              <div>{t("eu")}</div>
              <Link aria-label="Email" className="footer-detail" target="_blank" href="mailto:info@ingress.az">info@ingress.az</Link>
            </div>
          </li>
          <li>
            <div className="contact-logo">
              <BsFillTelephoneFill />
            </div>
            <div className="contact-text">
              <div>{t("pu")}</div>
              <Link aria-label="Phone" className="footer-detail" target="_blank" href="tel:+994 77 599 99 40">+994 77 599 99 40</Link>
            </div>
          </li>
        </ul>
      </div>
      <div className="footer-line">
        <div>
          &copy; {currentYear} Ingress Academy | {t("arr")}{" "}
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
