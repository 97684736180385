"use client";
import { useEffect, useRef, useState } from "react";

const useDrop = () => {
  const dropRef = useRef(null);
  const dropBtn = useRef(null);
  const [show, setShow] = useState("hide-drop");
  const toggleDropdown = () => {
    document.querySelectorAll(".dropdown").forEach((dropdown) => {
      if (dropdown !== dropRef.current) {
        dropdown.classList.remove("show-drop");
        dropdown.classList.add("hide-drop");
      }
    });

    setShow(show === "hide-drop" ? "show-drop" : "hide-drop");
  };

  const handleClickOutside = (event) => {
    if (
      !dropRef?.current?.contains(event.target) &&
      !dropBtn?.current?.contains(event.target)
    ) {
      setShow("hide-drop");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return { show, toggleDropdown, dropRef, dropBtn };
};

export default useDrop;
