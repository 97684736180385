"use client";
import Link from "next/link";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { ImLinkedin } from "react-icons/im";
import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";

import { useI18n } from "@/locales/client";
import useDrop from "../useDrop";

const Dropdown1 = ({ title, list = [], footer = false }) => {
  const { show, toggleDropdown, dropRef, dropBtn } = useDrop();
  const t = useI18n();
  return (
    <>
      <div className="relative">
        <button
          ref={dropBtn}
          type="button"
          className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
          aria-expanded="false"
          onClick={toggleDropdown}
        >
          {list.length > 0 ? (
            <>
              <span>{title} </span>
              {show === "hide-drop" ? (
                <MdKeyboardArrowDown />
              ) : (
                <MdKeyboardArrowUp />
              )}
            </>
          ) : (
            <Link
            aria-label="Page"
            rel='canonical'
              className="mr-2 font-['Muli_sans-serif'] font-normal text-[19px]"
              href={`/${title?.toLowerCase()}`}
            >
              {t(title?.toLowerCase())}
            </Link>
          )}
        </button>

        {list.length > 0 && (
          <div
            ref={dropRef}
            className={`absolute left-1/2 z-10 flex w-screen max-w-max -translate-x-1/2 px-4 ${show} dropdown`}
          >
            <div className=" max-w-md flex-auto overflow-hidden rounded-sm bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div className="px-4 py-4">
                {list?.map((li, index) => (
                  <Link
                  aria-label="Page"
                  rel='canonical'
                    onClick={toggleDropdown}
                    href={li?.path}
                    key={index}
                    className="group font-['Muli_sans-serif'] relative flex gap-x-6 rounded-lg px-4 py-2 hover:bg-[#e9ebf1] w-full"
                  >
                    <div>
                      <div className="text-gray-900 font-['Muli_sans-serif']">
                        {li?.label}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              {footer && (
                <div className="grid grid-cols-4 divide-x divide-gray-900/5 bg-[#eee]">
                  <Link
                  aria-label="Facebook"
                    href="https://www.facebook.com/ingress.academy"
                    target="_blank"
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-[#d6d6d6]"
                  >
                    <FaFacebook />
                  </Link>
                  <Link
                  aria-label="Instagram"
                    href="https://www.instagram.com/ingress_academy/"
                    target="_blank"
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-[#d6d6d6]"
                  >
                    <AiFillInstagram />
                  </Link>
                  <Link
                  aria-label="LinkedIn"
                    href="https://az.linkedin.com/school/ingressacademy/"
                    target="_blank"
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-[#d6d6d6]"
                  >
                    <ImLinkedin />
                  </Link>
                  <Link
                  aria-label="Youtube"
                    href="https://www.youtube.com/channel/UC4AT3cEfmyV-RcwxlJVBZ7Q"
                    target="_blank"
                    className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-[#d6d6d6]"
                  >
                    <FaYoutube />
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown1;
